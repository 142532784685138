@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

:root {
  --white: #ffffff;
  --primaryColor: #1D37E7;
  --primaryFont: "Inter", sans-serif;
  --secondaryColor: #f4f4f5;
  --textColor: #71717a;
  --emailHighlighter: #71717A;
  --textColorDark: #27272A;
  --emailHighlighter: #9FA4FA;
  --loadingColor: #C7C8FC;
  --loadingIconColor: #0000C0;
  --disableColor: #ebebeb;
  --borderColor: #dbdbdb;
  --dropBorderColor: #E2E8F0;
  --dropColor: #F8FAFC;
  --approveColor: #EDE9FE;
  --tabHeadColor: #5061F8;
  --verifyColor: #22C55E;
  --rejectColor: #c52258;
}

/* :root {
  --white: #ffffff;
  --primaryColor: #8b5cf6;
  --primaryFont: "Inter", sans-serif;
  --secondaryColor: #f4f4f5;
  --textColor: #71717a;
  --emailHighlighter: #71717A;
  --textColorDark: #27272A;
  --emailHighlighter: #C4B5FD;
  --loadingColor: #C4B5FD;
  --loadingIconColor: #6D28D9;
  --disableColor: #ebebeb;
  --borderColor: #dbdbdb;
  --dropBorderColor: #E2E8F0;
  --dropColor: #F8FAFC;
  --approveColor: #EDE9FE;
  --tabHeadColor: #A78BFA;
} */
.sectionWidth {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.marginBottom15 {
  margin-bottom: 15px;
}

.marginTop10 {
  margin-top: 10px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.lowerCase {
  text-transform: lowercase !important;
}

.marginBottom35 {
  margin-bottom: 35px;
}

.marginTop20 {
  margin-top: 20px;
}

.buttonwidth60 {
  width: 60% !important;
}

.marginTop15 {
  margin-top: 15px;
}

.backGround {
  background-color: #f8f8f8;
}

.paddingLeft100 {
  padding-left: 100px;
}

.paddingRight100 {
  padding-right: 100px;
}

.paddingTop50 {
  padding-top: 50px;
}

.displayFlex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paddingXY10 {
  padding-left: 15px;
  padding-right: 15px;
}

.paddingRL20 {
  padding: 20px 0px;
}

.displayHandler {
  display: block;
}

.displayHandlerMob {
  display: none;
}

.pointer {
  cursor: pointer !important;
}

.marginBottom0 {
  margin-bottom: 0px !important;
}

.fontWeight500 {
  font-weight: 500 !important;
}

.fontFamilyInter {
  font-family: var(--primaryFont);
}

.height100vh {
  height: 100vh !important;
}

.displayFlex {
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* overlay */
.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .displayHandler {
    display: none !important;
  }

  .displayHandlerMob {
    display: block;
  }
}