.dashBoardMainHead {
    padding: 50px 0px;
}

.dashBoardMainHead h1 {
    font-size: 45px;
}

.dashBoardMainHeadDesc {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.dashBoardMainHeadDesc p {
    font-size: 15px;
    color: var(--textColor);
    margin-right: 5px;

}

.dashBoardMainHeadDescButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.dashBoardMainHeadDesc img {
    margin-right: 5px;
}

.dashBoardMainHeadDesc a {
    font-size: 15px;
    color: var(--primaryColor);
    text-decoration: underline;
    text-underline-offset: 4px;
}


.dashBoardMainHeadDescBetween {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dashBoardMainHeadDescBetween p {
    font-size: 13px;
    color: var(--textColor);
    margin-right: 5px;

}

.dashBoardMainHeadDescButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.dashBoardMainHeadDescBetween img {
    margin-right: 5px;
}

.dashBoardMainHeadDescBetween h6 {
    font-size: 15px;
    color: var(--primaryColor);
    text-decoration: underline;
    text-underline-offset: 4px;
}

/* upgrade */
.upgradeTop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;

}

.upgradeTop h5 {
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.upgradeTop h4 {
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}

.upgradeTop h3 {
    font-weight: 600;
    font-size: 15px;
    opacity: 0.5;
    cursor: pointer;
}

.contactOverlay {
    width: 30%;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 600px) {
    .dashBoardMainHead h1 {
        font-size: 25px;
    }

    .dashBoardMainHeadDescBetween {
        flex-direction: column;
        align-items: flex-start;
    }

    .dashBoardMainHeadDescBetween p {
        font-size: 14px;
    }

    .dashBoardMainHead {
        padding: 30px 0px;
        padding-top: 15px;
    }
}