.shareTopHead {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shareTopHeadButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.shareTopHeadButton h5 {
    font-size: 16px;
    font-weight: 600;
    color: var(--primaryColor);
    margin-right: 20px;
    letter-spacing: 0.5px;
}

.shareTopHeadButton button {
    font-size: 14px;
    font-weight: 600;
    color: var(--white);
    margin-right: 20px;
    letter-spacing: 0.5px;
    background-color: var(--primaryColor);
    padding: 10px 25px;
    border-radius: 8px;
}

/* overlay */
.candidateEvaluation {
    background-color: var(--white);
    width: 40%;
    border-radius: 10px;
    /* height: 80%; */
}

.candidateEvaluationActive {
    background-color: var(--white);
    width: 40%;
    border-radius: 10px;
    height: 80%;
}

.candidateEvaluationHead {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 25px;
    background-color: var(--dropColor);
    border-radius: 10px;
    border-bottom: 1px solid var(--dropBorderColor);
}

.candidateEvaluationHead h1 {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
}

.candidateEvaluationHeadIcon {
    font-size: 30px;
    font-weight: 900 !important;
}

.candidateEvaluationBody {
    padding: 30px;
    padding-top: 15px;
    /* height: 80%; */
    /* overflow-y: scroll; */
}

.candidateEvaluationBodyActive {
    padding: 30px;
    padding-top: 15px;
    height: 80%;
    overflow-y: scroll;
}

.candidateEvaluationContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 12px;
    margin-top: 15px;
}

.candidateEvaluationContentActive {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 12px;
    margin-top: 15px;
    margin-bottom: 0px;
    border-bottom: none;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;

}

.candidateEvaluationContent h2,
.candidateEvaluationContentActive h2 {
    font-size: 16px;
    font-weight: 500;
    margin-left: 15px;
}

.candidateEvaluationButton {
    text-align: end;
    padding: 30px;
    padding-top: 0;
}

.candidateEvaluationButtonClose {
    border: 1px solid var(--textColorDark);
    padding: 8px 40px;
    border-radius: 8px;
    font-size: 14px;
}

.candidateEvaluationButtonConfirm {
    padding: 8px 40px;
    border-radius: 8px;
    font-size: 14px;
    color: var(--white);
    background-color: var(--primaryColor);
    margin-left: 15px;
}

.evaluationSuccessPopup {
    background-color: var(--white);
    width: 30%;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.candidateEvaluationContentDrop {
    padding: 15px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    border-top: none;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
}

.candidateEvaluationContentDrop h3 {
    font-size: 14px;
    font-weight: 500;

}

.candidateEvaluationContentDrop input {
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    border: 1px solid var(--borderColor);
    margin-top: 10px;
    margin-bottom: 15px;
}

.candidateEvaluationSubContentDrop {
    padding: 15px;
    padding-bottom: 0%;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.candidateEvaluationSubContentDrop h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
}

.preferContent textarea {
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    width: 100%;
}

.lightText {
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
    line-height: 25px;
}