.certificate {
    width: 100%;
    height: 100%;
    padding-bottom: 15px;
    position: relative;
}

.innerCertificateDesc {
    padding: 15px;
    border-radius: 8px;
    border: 1px solid var(--dropBorderColor);
}

.certificateHeadLeft {
    display: flex;
    align-items: center;
    justify-content: center;
}

.innerCertificate {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid var(--dropBorderColor);
}

.certificateHead {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border-bottom: 1px solid var(--dropBorderColor);
    background-color: var(--dropColor);
}

.bottomBorder {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: var(--dropColor);
}

.certificate h1 {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.certificateLeftIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.certificateLeftIconSvg {
    margin-right: 10px;
    height: 18px;
}

.certificateDesc {
    width: 100%;
    /* height: 300px; */
    background-color: var(--white);
    border-bottom-left-radius: 8px;
    border-bottom-Right-radius: 8px;
    padding: 30px;
}

.certificateDesc h1 {
    font-size: 15px;
    color: var(--textColor);
    margin-bottom: 20px;
    margin-left: 0;
}

.certificateDesc h5 {
    font-size: 20px;
    font-weight: 600;
    opacity: 0.6;
    margin-bottom: 3px;
}

.certificateDescFlex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.certificateDescFlex h3 {
    font-size: 14px;
    font-weight: 500;
}

.certificateDescFlex h4 {
    font-size: 14px;
    font-weight: 500;
}

.certificateDescFlex p {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.2;
    margin-left: 5px;

}

.certificateDesc p {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.2;
    margin-left: 5px;
}

.certificateDescFlexLast {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
    margin-top: 30px;

}

.certificateDescFlexLast h4 {
    font-size: 14px;
    font-weight: 500;
}

.certificateDescFlexLast p {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.5;
    margin-left: 5px;
}

.coursename {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.certificateDescUpload {
    border-radius: 8px;
    border: 1px solid var(--dropBorderColor);
    margin-bottom: 20px;
}

.certificateDescUploadDesc {
    width: 100%;
    /* height: 300px; */
    background-color: var(--white);
    border-bottom-left-radius: 8px;
    border-bottom-Right-radius: 8px;
    padding: 15px;
    padding-top: 15px;
    border-radius: 8px;
}

.certificateDescUploadDesc h1 {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.certificateHighLight {
    color: var(--primaryColor);
    margin-left: 2px;
    font-size: 14px;
    font-weight: 600
}

/* overLay */
.certificateDescOverlay {
    width: 65%;
    height: 640px;
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: scroll;
}

.outerCertificate {
    padding: 30px;
}

.certificateDescUploadDesc h1 {
    opacity: 0.5;
}

.uploadCertificate {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px dashed var(--borderColor);
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 8px;
    background-color: #F8FAFC;

}

.uploadCertificate h2 {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;

}

.uploadCertificate h3 {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    opacity: 0.3;
}

.addCertification {
    width: 92.5%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}

.addCertification h6 {
    font-size: 14px;
    font-weight: 600;
    opacity: 0.5;
    padding-bottom: 0px
}

.certificateDescOverlayFlex {
    display: flex;
    align-items: center;
    /* padding: 30px; */
    padding-top: 5px;
}

.certificateDescOverlayFlexLeft {
    width: 38%;
    margin-right: 2%;
}

.certificateDescOverlayFlexLeft h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.certificateDescOverlayFlexLeft input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

.certificateDescOverlayFlexRight {
    width: 58%;
    height: 100%;
    margin-left: 2%;
}

.certificateDescOverlayFlexRight h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
}

.certificateDescOverlayFlexRight input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
}

/* .certificateDescOverlayFlexRight select {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px solid var(--borderColor);
} */

.certificationUploaded {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    padding: 15px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border: 1px dashed var(--borderColor);
}

@media only screen and (max-width: 600px) {
    .certificateDescOverlay {
        width: 100%;
        height: 100%;
    }

    .certificateDescOverlayFlex {
        flex-direction: column;
    }

    .certificateDescOverlayFlexLeft {
        width: 100%;
        margin-right: 0px;
    }

    .certificateDescOverlayFlexRight {
        width: 100%;
        margin-Left: 0px;
    }
}