.verificationSuccess {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.verificationInner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.successerror {
    color: green;
    margin-top: 10px;
    font-weight: 500;
}

.logoutbtn {
    width: 90px;
    border-radius: 7px;
    border: 1px solid var(--borderColor);
    padding: 10px;
    color: var(--textColorDark);
    margin-top: 20px;
}

.verificationInnerHead {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;

}

.verificationInner p {
    font-size: 13px;
    font-weight: normal;
    line-height: 20px;
    color: var(--textColor);
}

.verificationInner p span {
    color: var(--textColor);
    /* font-weight: 400; */
    font-weight: bold;
}

.verificationInner p[title] {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--textColor);
}

.emailBox {
    border-top: 1px solid var(--borderColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 20px 0px;
    margin-top: 15px;
}

.emailBox button {
    border: 1px solid var(--borderColor);
    border-radius: 5px;
    padding: 8px 12px;
    color: var(--textColorDark);
    font-weight: 600;
    font-size: 15px;
    line-height: 18.15px;
}

.verificationBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.verificationBottom p {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--textColor);
}

.verificationBottom p[title] {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--textColor);
}

p[title] span {
    color: var(--primaryColor);
    cursor: pointer;
}


/* ========================= Mobile Number Verification ========================= */
.mobileVerification {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileInner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.mobileVerificationButtonActive {
    font-weight: normal;
    font-size: 12px;
    line-height: 18.15px;
    background-color: var(--primaryColor);
    color: white;
    padding: 10px;
    /* margin: 20px 0px; */
    border-radius: 8px;
}

.mobileVerificationButtonDisable {
    font-weight: normal;
    font-size: 12px;
    line-height: 18.15px;
    background-color: var(--disableColor);
    color: var(--textColor);
    padding: 10px;
    /* margin: 20px 0px; */
    border-radius: 8px;
}

.mobileVerificationBody {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileVerificationBody input {
    width: 80%;
    /* border: 1px solid var(--secondaryColor); */
    border: 1px solid var(--borderColor);
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 6px;
}

.mobileVerificationBody select {
    width: 30%;
    border: 1px solid var(--borderColor);
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    padding: 8px;
}

/* ========================= Otp Verification ============================= */
.optVerification {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.otpInner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.otpInner h2 {
    font-size: 24px;
    font-weight: bold;
    line-height: 29.05px;
    color: var(--textColorDark);
    padding: 5px 0px;
}

.otpInner p {
    font-size: 14px;
    color: var(--textColor);
    width: 380px;
    padding: 10px 0px 15px 0px;
    text-align: center;
}

.otperror {
    font-size: 12px !important;
    color: red !important;
    /* width: 380px;
    padding: 10px 0px 15px 0px; */
    text-align: center;
    font-weight: 600;
}

.otpInner p span {
    font-weight: bold;
}

.otpInput {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    margin: 10px 0px;
    width: 330px;

}

.otpInput input {
    margin-right: 8px;
    accent-color: var(--primaryColor);

}

.otpInput h6 {
    text-align: start;
    font-size: 14px;
    font-weight: 400;
}

.Btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Btn span {
    color: var(--primaryColor);
}

.Btn p {
    font-size: 14px;
    font-weight: normal;
}

.otpinput::-webkit-outer-spin-button,
.otpinput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.verifyBtn {
    width: 330px;
    background-color: var(--secondaryColor);
    padding: 10px 15px;
    border-radius: 7px;
    color: var(--textColor);
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;

}

.verifyBtn1 {
    width: 330px;
    background-color: var(--primaryColor);
    padding: 10px 15px;
    border-radius: 7px;
    color: white;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;

}

.verifyBtn2 {
    width: 330px;
    background-color: var(--loadingColor);
    padding: 10px 15px;
    border-radius: 7px;
    color: var(--white);
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.loadingIcon {
    color: var(--loadingIconColor);
    font-size: 22px;
    text-align: center;
    transform: rotate(180deg);
    animation: rotate 0.3s infinite;

}

@keyframes rotate {
    80% {
        transform: rotate(330deg);
    }
}

@media only screen and (max-width: 600px) {
    .verificationInner h1 {
        font-size: 20px;
    }

    .verificationInner p {
        text-align: center;
    }

    .emailBox button {
        font-size: 13px;
    }

    .otpInner h2 {
        font-size: 22px;
    }

    .otpInner p {
        font-size: 12px;
    }
}

.six-digit-form {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 20px;
}

.digit-input {
    width: 30px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
}