.recent {
    display: flex;
    overflow-x: scroll;
    z-index: 15;
}

.recent::-webkit-scrollbar {
    display: none;
}

.recentHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    margin-bottom: 15px;

}

.profilepic {
    height: 50px;
    height: 50px;
    border-radius: 50%;
}

.recentWrap {
    width: 515px !important;
    margin-right: 30px !important;
}

.swiper-slide.recentWrap.swiper-slide-active {
    width: 515px !important;
    margin-right: 30px !important;

}

.recentHeadRight h2 {
    color: var(--primaryColor);
    font-weight: 500;
}

.recentHead button {
    padding: 6px 8px;
    border-radius: 50%;
    margin-right: 15px;
    background-color: var(--white);
    border: 1px solid var(--borderColor);
}

.recentHeadLeft {
    display: flex;
}

.recentHead h1 {
    font-size: 16px;
    font-weight: 600;
    margin-right: 20px;
}

/* overlay */

.reserveCandidate {
    width: 60%;
    /* height: 640px; */
    background-color: white;
    display: flex;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.reserveCandidateBrief {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid var(--borderColor);
    padding-bottom: 20px;
}

.reserveCandidateBrief h5 {
    font-size: 12px;
    color: var(--textColor);
    font-weight: 500;
}

.reserveCandidateBrief h5 {
    margin-right: 5px;
    object-fit: cover;
    height: 20px;
}

.reserveHead {
    width: 100%;
    padding: 20px;
    /* border-radius: 8px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--borderColor);
}

.reserveHead h1 {
    font-size: 20px;
    font-weight: 600
}

.reserveCandidateFlex {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
}

.reserveCandidateFlexLeft {
    width: 55%;
}

.reserveCandidateFlexLeft h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}

.reserveCandidateFlexLeft li {
    font-size: 14px;
    font-weight: 400;
    list-style: disc;
    color: var(--textColor);
    margin-bottom: 10px;
}

.reserveCandidateFlexLeft ul {
    padding-left: 20px;
}

.darkHighter {
    font-size: 14px;
    font-weight: 600;
    color: var(--textColorDark);
    opacity: 0.9;
}

.reserveCandidateFlexRight {
    width: 45%;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    margin-left: 50px;
}

.reserveCandidateFlexRightHead {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.reserveCandidateFlexRightHeadLeft {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.reserveCandidateFlexRightHeadLeft img {
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
}


.reserveCandidateFlexRightHeadLeftDesc h3 {
    font-size: 16px;
    font-weight: 600;
}

.reserveCandidateFlexRightHeadLeftDesc h4 {
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
}

.css-qbdosj-Input {
    height: 36px;
}

.reserveCandidateBrief h5 {
    display: flex;

}

.reserveCandidateBrief img {
    margin-right: 5px;

}

.reserveCandidateFlexRight h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
}

.reserveCandidateFlexRight input {
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    margin-top: 15px;
}

.durationmMonths {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.durationmMonths h4 {
    font-size: 14px;
    font-weight: 600;
}

.durationmMonths h4 {
    font-size: 14px;
    font-weight: 600;
}

.fees {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fees h4 {
    font-size: 14px;
    font-weight: 600;

}

.reserveCandidateFlexRight h5 {
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
    margin-top: 10px;
    margin-bottom: 10px;
}


.reserveCandidateFlexRight button {
    padding: 10px;
    width: 100%;
    background-color: var(--primaryColor);
    border-radius: 8px;
    color: var(--white);
    margin-top: 10px;
    margin-bottom: 10px;
}

.reserveCandidateFlexRight p {
    font-size: 12px;
    font-weight: 400;
    color: var(--textColor);
}

.briefH5 {
    font-size: 12px;
    display: flex;
    align-items: center;

    margin-left: 7px;
    /* opacity: 0.5; */
}

.briefH5 img {
    height: 20px;
    opacity: 1;
    object-fit: cover;
}

/* success */
.reserveSuccess {
    width: 500px;
    background-color: white;
    display: flex;
    align-items: center;
    z-index: 10 !important;
    flex-direction: column;
    /* padding: 10px; */
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    padding-bottom: 30px;
}

.reserveSuccessClose {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.closeImage {
    width: 40px;
    height: 40px;
}

.reserveSuccess img {
    width: 70px;
    height: 70px;
}

.reserveSuccess h1 {
    font-size: 20px;
    font-weight: 600;
}

.reserveSuccess p {
    width: 55%;
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
    text-align: center;
}

.reserveSuccess button {
    padding: 8px 15px;
    border-radius: 8px;
    background-color: var(--primaryColor);
    color: var(--white);
    margin-top: 15px;
    margin-bottom: 15px;
}

@media only screen and (max-width: 600px) {
    .recentHead {
        padding-right: 0px;
    }

    .tableOne {
        padding: 25px;
    }

    .tableOne {
        padding-left: 0%;
        padding-right: 0%;
    }

    .profileCardContainer {
        padding-right: 0px;
    }

    .clientDiscoverOuter {
        padding: 0px;
    }

    .reserveCandidate {
        width: 100%;
        height: 100%;
    }

    .reserveCandidateFlex {
        height: 100%;
        overflow-y: scroll;
        flex-direction: column;
    }

    .reserveCandidateFlexLeft {
        width: 100%;
    }

    .reserveCandidateFlexRight {
        width: 100%;
        margin-left: 0%;
        margin-top: 10px;
    }
}